import React from "react";
import "../../index.css";

const Pricing = () => {
    return (

        <div className="pricing">
            <div className={"pricing-grid"}>
                <span>Cennik</span>
                <div className={"pricing-item"}>
                    <span>Narty pakiet - ostrzenie, smarowanie</span>
                    <span>100 PLN</span>
                </div>
                <div className={"pricing-item"}>
                    <span>Snowboard pakiet - ostrzenie, smarowanie</span>
                    <span>120 PLN</span>
                </div>
                <div className={"pricing-item"}>
                    <span>Ostrzenie krawędzi</span>
                    <span>80 PLN</span>
                </div>
                <div className={"pricing-item"}>
                    <span>Smarowanie na gorąco</span>
                    <span>50 PLN</span>
                </div>
                <div className={"pricing-item"}>
                    <span>Zalewanie ubytków</span>
                    <span>50 PLN</span>
                </div>
                <div className={"pricing-item"}>
                    <span>Regulacja wiązań</span>
                    <span>50 PLN</span>
                </div>
                <div className={"pricing-item"}>
                    <span>Montaż wiązań </span>
                    <span>50-100 PLN</span>
                </div>
            </div>
        </div>

    )
}

export default Pricing;














